<template>
    <div>
        <footer class="ftco-footer">
            <div class="container" id="contact">
                <div class="row mb-5 justify-content-between">
                    <div class="col-sm-12 col-md-5">
                        <div class="ftco-footer-widget mb-4">
                            <h2 class="ftco-heading-2 logo"><a href="#">{{site_content.shortname}}</a></h2>
                            <p>{{site_content.name}} <br/> {{site_content.welcometext}}</p>
                            <ul class="ftco-footer-social list-unstyled mt-2">
                                <li class=""><a :href="site_content.facebookurl" target="_blank"><span class="fa fa-facebook-square"></span></a></li>
                                <li ><a :href="site_content.youtubeurl" target="_blank" class="youtube"><span class="fa fa-youtube-square"></span></a></li>
                                <!-- <li ><a :href="site_content.lineurl" target="_blank" class="line"><span>L</span></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2">
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="ftco-footer-widget mb-4">
                            <h2 class="ftco-heading-2">Have a Questions?</h2>
                            <div class="block-23 mb-3">
                            <ul>
                                <li><span class="icon fa fa-map marker"></span><span class="text">{{site_content.addr}}</span></li>
                                <li><a href="#"><span class="icon fa fa-phone"></span><span class="text">{{site_content.phone}}</span></a></li>
                                <li><a href="#"><span class="icon fa fa-paper-plane pr-4"></span><span class="text">
                                    <span>{{site_content.email}}</span></span></a>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid px-0 py-1 bg-black">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mb-0" style="color: rgba(255,255,255,.5);">
                                Copyright &copy;2021 All rights reserved 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    props:{
        site_content:Object
    },
    data(){
        return{
            // site_content:{}
        }
    },
}
</script>

<style lang="scss" scoped>

</style>