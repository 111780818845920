<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

.ql-align-center{
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-content{
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
