<template>
    <div>
        <m-header v-bind:site_content="SiteContent" v-bind:showLanguage="showLanguage" v-bind:topics="topics"></m-header>
        <router-view></router-view>
        <m-footer v-bind:site_content="SiteContent"></m-footer>

        <!-- Modal Language -->
        <b-modal size="sm" v-model="mdLanguage" no-close-on-backdrop no-close-on-esc hide-header hide-footer>
            <b-row>
                <b-col>
                    <b-container fluid>
                        <h6>Language:</h6>
                        <b-row>
                            <b-col sm="2" class="text-center">
                                <img class="img-lang" :src="showLanguage.pic" />                                
                            </b-col>
                            <b-col>
                                <select class="form-control" v-model="showLanguage">
                                    <option v-for="(lang, index) in langs" :key="index"
                                        v-bind:value="lang"
                                    >
                                        {{lang.name}}
                                    </option>
                                </select>
                            </b-col>
                        </b-row>
                        
                        <b-button variant="outline-secondary" class="mt-2 pull-right" size="sm" @click="mdLanguage = false"><b-icon-x></b-icon-x> Cancel</b-button>
                    </b-container>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import SiteDataService from '../services/SiteDataService.vue';
import EventBus from '../Event-Bus.vue';

export default {
    name: 'SubPageLayout',
    components:{
        "m-header":MainHeader,
        "m-footer":MainFooter
    },
    data(){
        return{
            SiteContent:{},
            mdLanguage:false,
            showLanguage:{lang:"en",pic: require("../assets/images/united-states.png")},
            langs:[
                {name:"English", lang:"en",pic: require("../assets/images/united-states.png")},
                {name:"Thai", lang:"th",pic: require("../assets/images/thailand.png")}
            ],
            i18n:[],
            topics:[
                {id:1, val:"Home"},
                {id:2, val:"About"},
                {id:3, val:"Training&Seminar"},
                {id:4, val:"Choir Ministry"},
                {id:5, val:"News"},
                {id:6, val:"Contact"},
            ]
        }
    },
    methods:{
        GetSiteData(lang){
            SiteDataService.SiteContentGet(lang)
                .then(res => {
                    this.SiteContent = res.data;

                    if(this.i18n.length == 0)
                        this.GetMenulang();
                    else{
                        this.topics = [];
                        for(let i = 0; i < this.i18n.length; i++){
                            let _i18n = this.i18n[i];
                            let _tp = {
                                id: _i18n.id,
                                val: (lang == "en"? _i18n.en : _i18n.th)
                            };
                            this.topics.push(_tp);
                        }
                        
                        EventBus.$emit("AboutLabel", this.GetTopicId(2));
                        EventBus.$emit("TrainingLabel", this.GetTopicId(3));
                        EventBus.$emit("ChoirMinistryLabel", this.GetTopicId(4));
                        EventBus.$emit("NewsLabel", this.GetTopicId(5));
                        EventBus.$emit("ContactLabel", this.GetTopicId(6));
                        
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        SetShowLanguage(slang){
            let _lang = this.langs.filter(f => f.lang == slang)
            if(_lang != null && _lang.length > 0){
                this.showLanguage = _lang[0];
                this.GetSiteData(_lang[0].lang);
            }
            else
                this.GetSiteData("en");
        },
        GetMenulang(){
            SiteDataService.GetMenuMultiLanguage()
                .then(res => {
                    this.i18n = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        GetTopicId(id){
            let result = null;
            if(this.topics.length > 0){
                let tp = this.topics.filter(f => f.id == id);
                if(tp.length > 0)
                    result = tp[0].val;
                else
                    result = null;
            }
            else{
                result = null;
            }      
            return result;     
        }
    },
    watch:{
        showLanguage(n){
            this.$cookies.set('showlang',n.lang,'7d');
            this.GetSiteData(n.lang);
            this.mdLanguage = false;
            EventBus.$emit("siteLanguageChange", n.lang);
        }
    },
    created(){
        let cklang = this.$cookies.get('showlang');
        this.SetShowLanguage(cklang);
        this.GetMenulang();

        EventBus.$on("OpenModalChangeLanguage",() => {
            this.mdLanguage = true;
        });
    }
}
</script>

<style scoped>
/* @import url("../assets/css/thaicma_normal.css"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

.img-lang{
    max-width: 30px;
    height: auto;
}
</style>
