import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import VueSweetalert2 from 'vue-sweetalert2'
import VueCookies from 'vue-cookies'
import VueEasyLightbox from 'vue-easy-lightbox'

//--Style--
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/nucleo/css/nucleo.css'
import '@/assets/css/thaicma_normal.css'
import 'font-awesome/css/font-awesome.min.css'
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueScrollTo)
Vue.use(VueSweetalert2)
Vue.use(VueCookies)
Vue.use(VueEasyLightbox)

//--API--
Vue.prototype.$bkURL = "https://api.thaicma.org/";
// Vue.prototype.$bkURL = "http://localhost:4001/";

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')