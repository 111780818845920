<template>
    <div>
        <!-- <b-navbar toggleable="lg" type="dark" variant="info" 
            class="navbar-dark ftco_navbar bg-dark ftco-navbar-light scrolled awake" 
            id="ftco-navbar"
        >
            <div class="container">
                <b-navbar-brand href="/home">TCMA</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>

                    <div class="collapse navbar-collapse" id="ftco-nav">
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item href="/home">Home</b-nav-item>
                            <b-nav-item href="/about">About</b-nav-item>
                            <b-nav-item href="/activities">Traning&amp;Seminar</b-nav-item>
                            <b-nav-item href="/ChoirMinistry">Choir Ministry</b-nav-item>
                            <b-nav-item href="/news">News</b-nav-item>
                            <b-nav-item href="/contact">Contact</b-nav-item>
                        </b-navbar-nav>
                    </div>
                </b-collapse>
            </div>
        </b-navbar> -->

        <b-navbar toggleable="lg" type="dark" variant="info" 
            class="navbar-dark ftco_navbar bg-dark ftco-navbar-light scrolled awake" 
            id="ftco-navbar"
        >
            <div class="container">
                <b-navbar-brand href="/home"><img :src="MiniLogo" class="img-mini-logo" /></b-navbar-brand>
                <!-- <b-navbar-brand href="/home">{{site_content.shortname}}</b-navbar-brand> -->

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" class="" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item href="/home">{{topics[0].val}}</b-nav-item>
                        <b-nav-item href="/about">{{topics[1].val}}</b-nav-item>
                        <b-nav-item href="/activities">{{topics[2].val}}</b-nav-item>
                        <b-nav-item href="/ChoirMinistries">{{topics[3].val}}</b-nav-item>
                        <b-nav-item href="/news">{{topics[4].val}}</b-nav-item>
                        <b-nav-item href="/contact">{{topics[5].val}}</b-nav-item>
                        <b-nav-item @click="ShowChangeLanguge()">
                            <img class="img-lang" :src="showLanguage.pic" />
                        </b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
    </div>
</template>

<script>
import EventBus from '../Event-Bus.vue';

export default {
    props:{
        ScrollPoint:Number,
        site_content:Object,
        showLanguage:Object,
        topics:Array
    },
    data(){
        return{
            MiniLogo:require("../assets/images/tcma-logo-new.png")
        }        
    },
    methods:{
        ShowChangeLanguge(){
            EventBus.$emit("OpenModalChangeLanguage");
        }
    }
}
</script>

<style scoped>
.img-lang{
    max-width: 30px;
    height: auto;
}
</style>