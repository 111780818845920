<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    SiteContentGet(lang){
        return axios.get(Vue.prototype.$bkURL + "sitecontent?lang="+lang);
    },
    ActivityGets(){
        return axios.get(Vue.prototype.$bkURL + "classes");
    },
    ChoirMinistryGets(){
        return axios.get(Vue.prototype.$bkURL + "ChoirMinistries");
    },
    NewsGets(){
        return axios.get(Vue.prototype.$bkURL + "Blogs");
    },
    ChoirMinistryGetbyName(Name){
        return axios.get(Vue.prototype.$bkURL + "choirministry/name?key="+Name);
    },
    NewsGetbyName(Name){
        return axios.get(Vue.prototype.$bkURL + "blog/name?key="+Name);
    },
    ActivityGetbyName(Name){
        return axios.get(Vue.prototype.$bkURL + "class/name?key="+Name);
    },

    MemberTypeGets(){
        return axios.get(Vue.prototype.$bkURL + "membertypes");
    },
    ChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "churchs");
    },
    RespChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "respchurchs");
    },
    VocalGets(){
        return axios.get(Vue.prototype.$bkURL + "vocals");
    },

    MemberRegister(mb){
        return axios.post(Vue.prototype.$bkURL + "member/register",mb);
    },
    MemberSignIn(mbc,church_id){
        return axios.get(Vue.prototype.$bkURL + "member/auth?mbcode="+mbc+"&church="+church_id);
    },

    GetMenuMultiLanguage(){
        return axios.get(Vue.prototype.$bkURL + "i18n");
    },

    GetNewsGallery(nid){
        return axios.get(Vue.prototype.$bkURL + "Blog/gallery?id="+nid);
    },
    GetChoirGallery(cmid){
        return axios.get(Vue.prototype.$bkURL + "choirministry/gallery?id="+cmid);
    },

    CheckMember(name, surname){
        return axios.get(Vue.prototype.$bkURL + "member/check?name="+name+"&surname="+surname);
    },

    VisitorStamp(){
        return axios.get(Vue.prototype.$bkURL + "sa/stamp");
    },
    GetSiteAnalyze(){
        return axios.get(Vue.prototype.$bkURL + "sa");
    }
}
</script>