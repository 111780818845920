import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//--Layout--
import EmptyLayout from './layouts/EmptyLayout';
import SubPageLayout from './layouts/SubPageLayout';

const routes = [
    {
      path: '/',
      redirect: '/home',
      component: EmptyLayout,
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('./pages/HomePage.vue'),
          meta: {title:"หน้าแรก"}
        },
      ],      
    },
    {
      path: '/activities/:cate?',
      redirect: '/activities',
      component: SubPageLayout,
      children: [
        {
          path: '/activities/:cate?',
          name: 'activities',
          component: () => import('./pages/ActivitiesPage.vue'),
          meta: {title:"Activites"}
        },
      ],      
    },
    {
      path: '/activitiy/:name',
      redirect: '/activitiy',
      component: SubPageLayout,
      children: [
        {
          path: '/activity/:name',
          name: 'activity',
          component: () => import('./pages/ActivityContentPage.vue'),
          meta: {title:"Activity"}
        },
      ],      
    },
    // {
    //   path: '/ChoirMinistry',
    //   redirect: '/ChoirMinistry',
    //   component: SubPageLayout,
    //   children: [
    //     {
    //       path: '/ChoirMinistry',
    //       name: 'ChoirMinistry',
    //       component: () => import('./pages/ChoirMinistryPage.vue'),
    //       meta: {title:"Activites"}
    //     },
    //   ],      
    // },
    {
      path: '/ChoirMinistries/:cate?',
      redirect: '/ChoirMinistries',
      component: SubPageLayout,
      children: [
        {
          path: '/ChoirMinistries/:cate?',
          name: 'ChoirMinistries',
          component: () => import('./pages/ChoirMinistryPage.vue'),
          meta: {title:"Activites"}
        },
      ],      
    },
    {
      path: '/ChoirMinistry/:name',
      redirect: '/ChoirMinistry',
      component: SubPageLayout,
      children: [
        {
          path: '/ChoirMinistry/:name',
          name: 'ChoirMinistry',
          component: () => import('./pages/ChoirContentPage.vue'),
          meta: {title:"ChoirMinistry"}
        },
      ],      
    },
    {
      path: '/News',
      redirect: '/News',
      component: SubPageLayout,
      children: [
        {
          path: '/News',
          name: 'News',
          component: () => import('./pages/NewsPage.vue'),
          meta: {title:"Activites"}
        },
      ],      
    },
    {
      path: '/Register',
      redirect: '/Register',
      component: SubPageLayout,
      children: [
        {
          path: '/Register',
          name: 'Register',
          component: () => import('./pages/RegisterPage.vue'),
          meta: {title:"Register"}
        },
      ],      
    },
    {
      path: '/News/read/:name',
      redirect: '/News/read',
      component: SubPageLayout,
      children: [
        {
          path: '/News/read/:name',
          name: 'News',
          component: () => import('./pages/NewsContentPage.vue'),
          meta: {title:"News"}
        },
      ],      
    },
    {
      path: '/About',
      redirect: '/About',
      component: SubPageLayout,
      children: [
        {
          path: '/About',
          name: 'About',
          component: () => import('./pages/AboutPage.vue'),
          meta: {title:"AboutPage"}
        },
      ],      
    },
    {
      path: '/Contact',
      redirect: '/Contact',
      component: SubPageLayout,
      children: [
        {
          path: '/Contact',
          name: 'Contact',
          component: () => import('./pages/ContactPage.vue'),
          meta: {title:"Contact Page"}
        },
      ],      
    },
    {
      path: '/Member',
      redirect: '/Member',
      component: SubPageLayout,
      children: [
        {
          path: '/Member',
          name: 'Member',
          component: () => import('./pages/MemberPage.vue'),
          meta: {title:"Contact Page"}
        },
      ],      
    }
  ];

const router = new VueRouter({
    routes, // short for routes: routes
    linkActiveClass: 'active',
    mode: "history",
    scrollBehavior: (to, from ,savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return { selector: to.hash };
      }
      return { x: 0, y: 0 };
    },
    beforeEach: (to, from, next) => {
      document.title = to.meta.title;
      next();
    }
  });
  
  export default router;